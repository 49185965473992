var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;


// Font Resizer-------------------Start
function fontResizer() {
    var perc = parseInt(sw) / 118.9375;
    document.body.style.fontSize = perc + 'px';
}

// if (document.querySelector(".phone-group")) {
//     let input = document.getElementById("phone");
//     var iti = intlTelInput(input, {
//         initialCountry: "ae",
//         separateDialCode: true,
//     });
// }
// Loader-------------------Start

function pageLoader() {
    // document.querySelector("html").classList.add("loadjs");
    setTimeout(function () {
        document.querySelector("html").classList.add("loaded");
    }, 800);

    setTimeout(function () {
        document.querySelector(".loader-first").style.opacity = '0';
        document.querySelector(".loader-first").style.zIndex = '-1';
        document.querySelector(".loader-first").style.transition = '2s';
    }, 300);
}


// Navigation slide Active on a mobile 
// let toggleMenu = document.querySelector('#toggle');
// let htmlEl = document.querySelector("html");

// toggleMenu.addEventListener("click", (event) => {
//     console.log('this', event)
//     let _this = event.target;
//     _this.classList.toggle('active');
//     document.querySelector('.mega-menu').classList.toggle('open')
//     htmlEl.classList.toggle("overflow-hidden");
// })

// accordion
var accordion = document.getElementsByClassName("faqs__faq-accordion");
var j;

for (j = 0; j < accordion.length; j++) {
    accordion[j].querySelector('h4').addEventListener("click", function () {
        this.classList.toggle("opened");

        /* Toggle between hiding and showing the active panel */
        var panel = this.nextElementSibling;
        // console.log('checking height', panel.style.maxHeight)
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    });
}

// Landscape Mode off 
function landscapeModeOff() {
    if (sh < 450 && sw > 480 && sw < 820) {
        document.getElementById("portrait-warnning").style.display = "flex";
    } else {
        document.getElementById("portrait-warnning").style.display = "none";
    }
}


// Sticky Header----------------Start
function stickyHeader() {
    if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
    } else {
        header.classList.remove("sticky");
    }
}

// Navigation Active-------------------Start
let toggleMenu = document.querySelector("#toggle");

toggleMenu.addEventListener("click", (event) => {
    console.log("this", event);
    let _this = event.target;
    _this.classList.toggle("active");
    document.querySelector(".navigation").classList.toggle("open");
    document.querySelector("body").classList.toggle("overflow-hidden");
    document.html.classList.toggle("overflow-hidden");
    document.querySelector(".search-box").classList.toggle("open");

});


// Search Open -------------------Start
document.querySelector('.search-btn').addEventListener('click', function (event) {
    event.preventDefault();
    var target = document.querySelector('.search-box');
    if (target.classList.contains('open')) {
        target.classList.remove('open');
    } else {
        target.classList.add('open');
    }
    var target = document.querySelector('.search-btn');
    if (target.classList.contains('open')) {
        target.classList.remove('open');
    } else {
        target.classList.add('open');
    }
    var target = document.querySelector('.nav-button');
    if (target.classList.contains('d-none')) {
        target.classList.remove('d-none');
    } else {
        target.classList.add('d-none');
    }
    // var target = document.querySelector('.language-wrapper');
    // if (target.classList.contains('d-none')) {
    //     target.classList.remove('d-none');
    // } else {
    //     target.classList.add('d-none');
    // }
    var target = document.querySelector('.search-languege-wrapper');
    if (target.classList.contains('w-100')) {
        target.classList.remove('w-100');
    } else {
        target.classList.add('w-100');
    }
    // var target = document.querySelector('body');
    // if (target.classList.contains('overflow-hidden')) {
    //     target.classList.remove('overflow-hidden');
    // } else {
    //     target.classList.add('overflow-hidden');
    // }
});

// if (document.querySelector(".show-category")) {
//     document.querySelector('.show-category').addEventListener('click', function (event) {
//         event.preventDefault();
//         var target = document.querySelector('.show-category');
//         if (target.classList.contains('show')) {
//             target.classList.remove('show');
//         } else {
//             target.classList.add('show');
//         }
//     });
// }

if (sw < 1200) {
    let mobileToggle = document.querySelector(".mobileToggle,.has-child-menu");

    mobileToggle.addEventListener("click", (event) => {
        document.querySelector(".mobileToggle").classList.toggle("open--menu");
        document.querySelector(".inner-menu").classList.toggle("openMegaMenu");
    });
}



// function setNavigation() {
//     let current_location = location.pathname.split("/")[1];
//     if (current_location === "") return;
//     let menu_items = document.querySelector("header").getElementsByTagName("a");
//     for (let i = 0, len = menu_items.length; i < len; i++) {
//         if (menu_items[i].getAttribute("href").indexOf(current_location) !== -1) {
//             menu_items[i].classList.add("active");
//         }
//     }
// }
// setNavigation();



// Window on Load-----------------Start
window.onload = function () {
    pageLoader();
    if (sw > 1199) {
        fontResizer();
    }
    // SVG Convert Code
    let svgs = document.querySelectorAll('img.svg-convert');
    svgs.forEach((el) => {
        const imgID = el.getAttribute('id');
        const imgClass = el.getAttribute('class');
        const imgURL = el.getAttribute('src');

        fetch(imgURL)
            .then((response) => response.text())
            .then(data => {
                const parser = new DOMParser();
                const xmlDoc = parser.parseFromString(data, 'text/html');
                let svg = xmlDoc.querySelector('svg');
                if (typeof imgID !== 'undefined') {
                    svg.setAttribute('id', imgID);
                }

                if (typeof imgClass !== 'undefined') {
                    svg.setAttribute('class', imgClass + ' svg-converted');
                }

                svg.removeAttribute('xmlns:a');

                el.parentNode.replaceChild(svg, el);
            })
    });

};

// Window On Resize-----------------Start
window.addEventListener('resize', (event) => {
    sw = window.innerWidth;
    sh = window.innerHeight;
    landscapeModeOff();
    if (sw > 1025) {
        if (sw < 1400 && sw > 1300 && sh > 900) { } else {
            fontResizer();
        }
    }
});


// Window On Scroll-----------------Start
window.addEventListener('scroll', (event) => {
    stickyHeader();
});

let anim = [...document.querySelectorAll(".total-vacants")];
const counters = document.querySelectorAll(".counter");

function signFunc() {
    counters.forEach((counter) => {
        counter.innerText = "0";
        const updateCounter = () => {
            const target = +counter.getAttribute("data-target");
            const count = +counter.innerText;
            const increment = target / 200;
            if (count < target) {
                counter.innerText = `${Math.ceil(count + increment)}`;
                setTimeout(updateCounter, 1);
            } else counter.innerText = target;
        };
        updateCounter();
    });
}

let signOptions = {
    rootMargin: "0px 0px 300px 0px",
    threshold: 0.2,
};

let signAnimate = (entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            setTimeout(function () {
                entry.target.classList.add("active");
            }, 1500);
            if (entry.target.classList.contains("active")) { } else {
                signFunc();
            }
        }
    });
};

let signObserver = new IntersectionObserver(signAnimate, signOptions);

anim.forEach((item, idx) => {
    signObserver.observe(item);
});

let innerLink = document.querySelectorAll('.js-inner')
innerLink.forEach(el => el.addEventListener('click', e => functionforscroll(e.target.getAttribute('data-id'))))

function functionforscroll(id) {
    var getMeTo = document.getElementById(id);
    setTimeout(function () {
        getMeTo.scrollIntoView();
    }, 100)
    var uri = window.location.toString();
    if (uri.indexOf("#") > 0) {
        var clean_uri = uri.substring(0, uri.indexOf("#"));
        window.history.replaceState({}, document.title, clean_uri);
    }
}

var teamSlider = new Swiper(".team-slider", {
    slidesPerView: 1,
    speed: 1500,
    spaceBetween: 30,
    breakpoints: {
        768: {
            slidesPerView: 2,
            spaceBetween: 15,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 29,
        },
    },
    navigation: {
        nextEl: ".our-team .swiper-button-next",
        prevEl: ".our-team .swiper-button-prev",
    },
});


var gallerySlider = new Swiper(".gallery-slider", {
    // slidesPerView: 1,
    loop: true,
    speed: 2000,
    observer: true,
    observeParents: true,
    spaceBetween: 0,
    centeredSlides: true,
    draggable: true,
    effect: 'coverflow',
    coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 0,
        modifier: 0,
        slideShadows: false,
    },
    // autoplay: {
    //     enable: true,
    //     delay: 2500,
    //     disableOnInteraction: true,
    // },
    navigation: {
        nextEl: ".gallery .swiper-button-next",
        prevEl: ".gallery .swiper-button-prev",
    },
    breakpoints: {
        576: {
            spaceBetween: 0,
            slidesPerView: 2,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 215,
                modifier: 1,
                slideShadows: false,
            },
        },
        1024: {
            spaceBetween: 0,
            slidesPerView: 2,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 215,
                modifier: 1,
                slideShadows: false,
            },
        },
        1199: {
            spaceBetween: 0,
            slidesPerView: 2,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 215,
                modifier: 1,
                slideShadows: false,
            },
        },
        1400: {
            spaceBetween: 0,
            slidesPerView: 2,
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 215,
                modifier: 1,
                slideShadows: false,
            },
        },
    },
});

function addIFrame(slide, index) {
    slide.forEach(item => {
        $(item).find('.iframeVidz').remove();
    })
    let dsrc1 = $(slide[index]).find(".iframeData").attr("data-src");
    let iFrame1 =
        '<iframe class="iframeVidz" src="' + dsrc1 + '" style=""></iframe>';
    setTimeout(function () {
        $(slide[index]).find(".iframeData").append(iFrame1);
    }, 500);
};

var smImgSlider = new Swiper(".small-img-slider", {
    loop: true,
    spaceBetween: 20,
    slidesPerView: 2,
    freeMode: true,
    watchSlidesProgress: true,
    loop: true,
    autoplay: {
        delay: 2500,
    },
    breakpoints: {
        767: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        1200: {
            slidesPerView: 4,
            spaceBetween: 30,
        },
    },
});


document.querySelectorAll(".pet-profile").forEach(function (s) {

    let slider = s.querySelector(".big-img-slider")
    let sliderThumb = s.querySelector(".small-img-slider")
    new Swiper(slider, {
        loop: true,
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },

        thumbs: {
            swiper: sliderThumb,
        },
    });

});



var footerSlider = new Swiper(".footer-dog-slider", {
    slidesPerView: 4,
    speed: 1500,
    spaceBetween: 10,
    breakpoints: {
        1024: {
            slidesPerView: 6,
            spaceBetween: 30,
        },
        1200: {
            slidesPerView: 8,
            spaceBetween: 30,
        },
    },
});


var newsCardsSlider = new Swiper(".news-cards-slider", {
    slidesPerView: 1,
    speed: 1500,
    spaceBetween: 20,
    breakpoints: {
        767: {
            slidesPerView: 2,
            spaceBetween: 30,
        },
        1200: {
            slidesPerView: 3,
            spaceBetween: 30,
        },
    },
    navigation: {
        nextEl: ".news-cards-detail .swiper-button-next",
        prevEl: ".news-cards-detail .swiper-button-prev",
    },
});


if (document.querySelector(".phone-group")) {
    let input = document.getElementById("phone");
    var iti = window.intlTelInput(input, {
        hiddenInput: "full_phone",
        initialCountry: "ae",
        separateDialCode: true,
    });
    console.log('iti.getNumber();', iti.getSelectedCountryData());
    var hidden = document.querySelector("[name='full_phone']");

    var handleChange = function () {
        hidden.value = '+' + iti.getSelectedCountryData().dialCode + input.value


    }

    input.addEventListener('change', handleChange);
    input.addEventListener('keyup', handleChange)

    input.addEventListener("countrychange", function () {
        hidden.value = '+' + iti.getSelectedCountryData().dialCode + input.value

    });
}
